import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateagoPipe } from './dateago.pipe';

@NgModule({
  declarations: [DateagoPipe],
  imports: [
    CommonModule
  ],
  exports : [DateagoPipe],
  providers : [DateagoPipe]
})
export class DateagoModule { }
