
import { Inject,Injectable,InjectionToken,NgZone } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendHost } from '../_host/host';
import { Router } from '@angular/router';
import { map,catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject,BehaviorSubject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';


declare var window: any;

let httpOptions = {
  headers: new HttpHeaders({
    'x-access-token': localStorage.getItem('kairaatestnet') || ""
  })
};


@Injectable({
  providedIn: 'root'
})
export class DataService {

    public currentAccount: any;
       

    public logout_flag = false;
    isListItemSelected : any = {};
    

	serviceHost = BackendHost;
	headers: Headers;
	options: RequestOptions;
	redirectDelay: number = 0;

  	constructor(private http: HttpClient,
  				protected router: Router,
  				public toastr: ToastrService,
                private ngxService: NgxSpinnerService,
                private meta: Meta, private metattitle: Title,
               private zone: NgZone) { 
                for(let i =0; i<=7; i++)
                {
                  if(i == 2)
                  {
                    this.isListItemSelected[i] = true;
                  }
                  else
                  {
                    this.isListItemSelected[i] = false;
                  }
                }    
            }


  	private extractData(res: Response) {
	    let body = res;
	    return body || {};
	}

  	private handleError(error: any) {
	    let errMsg = (error.message) ? error.message :
	    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
	    return Observable.throw(errMsg);
	}

  updateHeaderAuth(value: any){
    httpOptions.headers = httpOptions.headers.set('x-access-token',value);
}

  	requestData(url, values): Observable<any> {
		var postdata =  this.http
			.post(this.serviceHost + url, values, httpOptions)
			.pipe(map(this.extractData))
				.pipe(catchError(this.handleError)
			);

		return postdata;	
	}

  /*  Get data from backend server */
    getData(domain): Observable < any > {
        var returndata = this.http.get(this.serviceHost +  domain,httpOptions)
                        .pipe(map(this.extractData))
                        .pipe(catchError(this.handleError)
                          );
                          return returndata;
    }

	readFile(type: String, file: any, elem: String) {        
        let isValid = this.checkFileFormat(type, file.target.files[0].name);
        return new Promise(function(resolve,reject){
          if (isValid.valid !== -1) {
              var reader = new FileReader();
              reader.onload = function(e: any) {                  
                  if (isValid.format == "pdf") {
                    resolve('assets/pdf.png');
                  } else {
                    resolve(e.target.result);
                  }
              };
              reader.readAsDataURL(file.target.files[0]);
          } else {
              reject(false);
          }
        });       
    }

    readCreatePopupFile(type: String, file: any, elem: String) {        
        let isValid = this.checkFileFormat(type, file.target.files[0].name);
        return new Promise(function(resolve,reject){
          if (isValid.valid !== -1) {
              var reader = new FileReader();
              reader.onload = function(e: any) {                  
                  if (isValid.format == "pdf") {
                    resolve('assets/pdf.png');
                  } else {
                    resolve(e.target.result);
                  }
              };
              reader.readAsDataURL(file.target.files[0]);
          } else {
              reject(false);
          }
        });       
    }

    startLoader(){
         this.ngxService.show(); 
    }
    stopLoader(){
         this.ngxService.hide(); 
    }
    
    checkFileFormat(type: String, name: String) {        
        if (type == 'file') {
            var acceptedFiles = ['jpg', 'png', 'jpeg', 'gif', 'pdf'];
        } else {
            var acceptedFiles = ['jpg', 'png', 'jpeg', 'gif'];
        }
        var file_name = name;
        var split_name = file_name.split(".");
        let split_name_1 = split_name[1].toLowerCase();
        var isValid = acceptedFiles.indexOf(split_name_1);
        return {
            "valid": isValid,
            "format": split_name_1
        };
    }

    checkLogin(){
      let check = localStorage.getItem('kairaatestnet')
      if(check){
          if(typeof check == 'string' && check != ""){
              return true;
          }
          else{
              return false
          }
      }
      else{
          return false
      }
  }
    

    getRecaptchaSiteKey(): string { 
      return '6Lcxf1AhAAAAAJslGI2BeCJvVmxh1AhLfGb_u_Sh';
  }    

  	checkValid(response: any){
       
        if(response.status == 442){
            this.toastr.info("You don't have access to access this page!",'Information');
        }else if(response.status == 700){
          this.toastr.error("You session is over, So kindly login again with your credentials",'Information');
          localStorage.clear();
          this.router.navigate(['/']);
        }else if(response.status == 401){
            this.toastr.error("Unauthorized Request",'Warning!');
        }else if(response.status == 701){
            this.toastr.error("You session is over, So kindly login again with your credentials",'Information');
            localStorage.clear();
        }else if(response.status == 708){
            this.toastr.error("Site Maintenance",'Information');
            localStorage.clear();
            this.router.navigate(['/under-maintainence']);
        }     
    }

  	logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('kairaatestnet');
        localStorage.removeItem('login_time');
        sessionStorage.removeItem('kairaatestnet');
        this.router.navigate(['/']);
        this.toastr.success('Logout done Successfully',"Success")
    }

     idleLogout() {
        let _self = this;
        var t;
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;       
        window.ontouchstart = resetTimer; 
        window.onclick = resetTimer; 
        window.onkeypress = resetTimer;
        window.addEventListener('scroll', resetTimer, true); 
        function timoutfunction() {
            localStorage.clear();
            sessionStorage.clear();
            _self.toastr.error("You session is over, So kindly login again with your credentials",'Information');
            window.location.href = '/';
        }

        function resetTimer() {
            clearTimeout(t);
            t = setTimeout(timoutfunction, 900000); 
        }
    }

    check_ipblock() {
        return true;
     
    }

    setMeta(title) {
        if (title != '') {
          this.metattitle.setTitle(title);
        }
       
      }


}