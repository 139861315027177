import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { BackendHost } from '../_host/host';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';

const host = BackendHost;

console.log(`host----10-----,socket.service------>`, host);

@Injectable({
	providedIn: 'root'
})
export class SocketService {


	constructor(private socket: Socket,
		private http: HttpClient) {
		this.socket = io('https://kairaascan.io/', { transports: ['polling'] });
	}



	home_details = (soc_data) => {
		return Observable.create((observer) => {
			this.socket.on(soc_data, (message) => {
				observer.next(message);
			});
		});
	}

	checkLoginUser = () => {
		return Observable.create((observer) => {
			this.socket.on('checkLogin', (message) => {
				observer.next(message);
			});
		});
	}



	getblock = () => {
		return Observable.create((observer) => {
			this.socket.on('getblock', (message) => {
				// console.log(`message----45-----,socket.service------>`, message);
				observer.next([message]);
			});
		});
	}


	_checkUndermaintenance = () => {
		return Observable.create((observer) => {
			this.socket.on('underMaintance_INKS', (message) => {
				observer.next(message);
			});
		});
	}
	trigger(name, content) {
		this.socket.emit(name, content);
	}
}
