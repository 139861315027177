import { Component, HostListener,OnInit } from '@angular/core';
import { DataService } from './services/data.service';
import { SocketService } from './services/socket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'KairaaChain';

  

  constructor(public service : DataService,
              private router: Router,
              private socket : SocketService,
              private toastr : ToastrService,
              private route: ActivatedRoute,) {


      document.addEventListener('contextmenu', event => event.preventDefault());
      document.onkeydown = function(e) {
          if(e.keyCode == 123) {
             return false;
          }
          if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
             return false;
          }
          if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
             return false;
          }
          if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
             return false;
          }
          if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
             return false;
          }
      }

  }

    ngOnInit(){

    }
  
}
