
let Backend = '';
let Frontend = '';
let host = document.location.hostname;
// Backend = 'http://localhost:8967/';
// Frontend = 'http://localhost:8967/';


Backend = 'https://kairaascan.io/';
Frontend = 'https://kairaascan.io/';


export var BackendHost = Backend;
export var FrontendHost = Frontend;

